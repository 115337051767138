import Swup from 'swup';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupMorphPlugin from 'swup-morph-plugin';

export function runSwup() {
    const swup = new Swup({
        skipPopStateHandling: (event) => event.state?.source !== 'swup',
        plugins: [
            new SwupHeadPlugin({
                persistAssets: true
            }),
            new SwupBodyClassPlugin(),
            new SwupMorphPlugin({
                containers: ['#nav']
            })
        ]
    });

    swup.hooks.on('content:replace', init);

    init();

    function init() {
        setTimeout(() => {
            document.documentElement.style.setProperty('--nav-height', `${document.querySelector('nav').scrollHeight}px`);
            if (document.querySelector('#footer')?.scrollHeight) {
                document.documentElement.style.setProperty('--footer-height', `${document.querySelector('#footer').scrollHeight}px`);
            }
            else {
                document.documentElement.style.setProperty('--footer-height', '0px');
            }
        }, 200);
    }

    window.swup = swup;
}


