import { controlsVideo } from '../components/video';
import { runSlider } from '../partials/gallery';

export class Project {
    index() {
        // runSlider();
        controlsVideo();
        return () => { };

    }
}