import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

let parentHeight = 0;
let magic = null;

function runSlider() {
    const projectsContainer = document.querySelector('.projects');

    if (!projectsContainer || projectsContainer.getBoundingClientRect().width < window.innerWidth || window.innerWidth < 768) {
        if (!projectsContainer || projectsContainer.getBoundingClientRect().width < window.innerWidth || window.innerWidth < 768) {
            return;
        }

        setTimeout(() => {
            magic = runMagic(projectsContainer);
        }, 10);


    }

    function runMagic(projectsContainer) {
        projectsContainer.parentElement.style.height = `${projectsContainer.offsetWidth}px`;
        magic = runMagic(projectsContainer);
    }


}

function runMagic(projectsContainer) {
    projectsContainer.parentElement.style.height = `${projectsContainer.offsetWidth}px`;

    gsap.to(projectsContainer, {
        x: () => -(projectsContainer.scrollWidth - projectsContainer.parentElement.offsetWidth),
        ease: 'none',
        scrollTrigger: {
            trigger: projectsContainer.closest('#page'),
            start: 'top top',
            end: 'bottom bottom',
            scrub: true
        }
    });
}

function revealCards() {
    let cards = document.querySelectorAll('.project');

    cards.forEach((card) => {
        gsap.from(card, {
            // opacity: 0,
            y: 50,
            // stagger: 1,
            ease: 'power4',
            duration: 1,
            scrollTrigger: {
                trigger: card,
                start: 'top bottom',
                // end: 'bottom bottom+=100px',
                // scrub: true
            }
        });
    });
}

// function revealCards() {
//     let cards = document.querySelectorAll('.project');

//     gsap.from(cards, {
//         opacity: 0,
//         y: 100,
//         ease: 'power4',
//         scrollTrigger: {
//             trigger: (i) => cards[i], // Utilisez le premier élément comme déclencheur si tous les éléments sont censés s'animer ensemble
//             start: 'top bottom',
//             end: 'top bottom+=100px',
//             scrub: true
//         }
//     });
// }

export class Projects {
    index() {
        // runSlider();
        revealCards();
        return () => { };

    }
}