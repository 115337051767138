import { ScrollTrigger } from 'gsap/all';

export class Router {

    constructor(pages = {}, swup) {
        this.pages = pages;
        this.swup = swup;

        this.routeContainer = document.querySelector('#page');

        if (this.routeContainer && this.getRoute() && this.pages[this.getRoute().controller]) {
            this.cleanup = this.dispatchRoute();
            this.swup.hooks.on('content:replace', () => {
                this.cleanup = this.dispatchRoute();
                ScrollTrigger.refresh();
                // window.dispatchEvent(new Event('router:pageChange'));
            });
            this.swup.hooks.before('content:replace', () => {
                this.cleanup();
            });
        } else {
            console.error('No route found');
        }
    }

    getRoute() {
        this.routeContainer = document.querySelector('#page');
        let controller = this.routeContainer.dataset.controller;

        if (!controller) {
            return console.error('No controller found');
        }

        controller = controller.charAt(0).toUpperCase() + controller.slice(1);
        return { controller };
    }

    dispatchRoute() {
        let Page = null;

        if (!this.getRoute() || !this.pages) {
            this.cleanup = () => { };
        } else {
            console.log(this.getRoute().controller);

            Page = this.pages[this.getRoute().controller];
            const current = new Page();
            return current.index();
        }


    }


}