import Lenis from '@studio-freight/lenis';

export function runInifiniteScroll() {
    const background = document.querySelector('#background');

    if (!background) {
        return;
    }
    const lenisInner = new Lenis({
        wrapper: background,
        smooth: true,
        infinite: true,
    });

    function raf(time) {
        lenisInner.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // const repeatItems = (parentEl, total = 0) => {
    //     const items = [...parentEl.children];
    //     for (let i = 0; i <= total - 1; ++i) {
    //         const item = items[i];
    //         console.log(item);
    //         if (item) {
    //             var cln = item.cloneNode(true);
    //             parentEl.appendChild(cln);
    //         }
    //     }
    // };
    // const gridElement = document.querySelector('.grid');
    // if (gridElement.hasChildNodes()) {
    //     repeatItems(gridElement, 10);
    // } else {
    //     console.log('No children in the grid element');
    // }
    setTimeout(() => {
        autoplay();
    }, 500);

    function autoplay() {
        const diviser = 30;
        lenisInner.scrollTo('bottom', {
            duration: background.scrollHeight / diviser - ((background.scrollHeight * lenisInner.progress) / diviser),
            easing: (x) => x,
            onComplete: () => {
                setTimeout(() => {
                    lenisInner.scrollTo('top', {
                        immediate: true // Skip the smooth scrolling animation
                    });
                    autoplay();
                }, 0);
            }
        });
    }

    function reveal() {
        const revealItems = background.querySelectorAll('.reveal');
        // revealItems.forEach((item) => {
        //     const observer = new IntersectionObserver((entries) => {
        //         entries.forEach((entry) => {
        //             if (entry.isIntersecting) {
        //                 item.classList.add('is-visible');
        //                 observer.unobserve(item);
        //             } else {
        //                 // item.classList.remove('is-visible');
        //             }
        //         });
        //     });
        //     observer.observe(item);
        // });
        setTimeout(() => {
            revealItems.forEach((item) => {
                item.classList.add('is-visible');
            });
        }, 500);

        // setTimeout(() => {
        //     volumeOnHover();
        // }, 500);

        function volumeOnHover() {
            const medias = background.querySelectorAll('video');
            medias.forEach((media) => {
                media.addEventListener('mouseover', () => {
                    medias.forEach((media) => {
                        media.volume = 0;
                        media.muted = true;
                    });
                    media.muted = false;
                    media.volume = .2;
                });
            });
        }

    }

    reveal();

    // const medias = background.querySelectorAll('img, video');
    // medias.forEach((media) => {
    //     media.addEventListener('mouseover', () => {
    //         media.addEventListener('mouseleave', (e) => {
    //             autoplay();
    //         });
    //     });
    // });



    lenisInner.on('scroll', (instance) => {
        if (!instance.isScrolling) {
            setTimeout(() => {
                autoplay();
            }, 1);
        }
    });
}