import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

function runSlider() {
    const members = document.querySelector('.members');
    const membersContainer = members.closest('.members-container');

    // if (!members || members.getBoundingClientRect().width < window.innerWidth || window.innerWidth < 768) {
    //     return;
    // }
    // setTimeout(() => {

    //     gsap.to(membersContainer, {
    //         x: () => -(membersContainer.scrollWidth - membersContainer.parentElement.offsetWidth),
    //         ease: 'none',
    //         scrollTrigger: {
    //             trigger: membersContainer.closest('#page'),
    //             start: 'top top',
    //             end: 'bottom bottom',
    //             scrub: true
    //         }
    //     });
    // }, 10);
    // console.log(galleryList.getBoundingClientRect().width, innerWidth);
    ScrollTrigger.refresh();

    // const container = members.closest('.members-container').parentElement;

    setTimeout(() => {
        magic(members, membersContainer);
    }, 10);
}

function magic(members, membersContainer) {
    membersContainer.style.height = `${members.offsetWidth / 2}px`;
    membersContainer.style.setProperty('--members-height', `${members.scrollHeight}px`);

    gsap.to(members, {
        x: () => -(members.scrollWidth - membersContainer.offsetWidth),
        ease: 'none',
        scrollTrigger: {
            trigger: membersContainer.parentElement,
            start: 'top top+=100',
            end: 'bottom bottom',
            scrub: true,
        }
    });
}

export class Team {
    index() {
        // runSlider();
        return () => { };
    }
}