// import { runSlider } from '../partials/gallery';
import { runInifiniteScroll } from '../partials/infinite-scroll';
import { Page } from './page';

export class About extends Page {
    index() {
        super.init();
        // runSlider();
        runInifiniteScroll();
        return () => { };
    }
}