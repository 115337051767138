export class FullscreenImage extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', this.openWrapper);
    }

    createWrapper() {
        const div = document.createElement('div');
        div.className = 'fullscreen-image-wrapper';
        const image = document.createElement('img');
        image.src = this.dataset.src ?? this.querySelector('img').src;
        div.appendChild(image);
        this.wrapper = div;
    }

    openWrapper() {
        this.createWrapper();
        document.body.appendChild(this.wrapper);
        setTimeout(() => {
            this.wrapper.classList.add('open');
        }, 0);
        this.wrapper.addEventListener('click', this.closeWrapper.bind(this));
        document.addEventListener('keyup', (e) => {
            console.log(e);
            if (e.key === 'Escape') this.closeWrapper();
        });

        this.createPointerTracker();
    }

    closeWrapper() {
        this.wrapper.classList.remove('open');
        this.closePointerTracker();

        setTimeout(() => {
            this.wrapper.remove();
        }, 600);
    }

    remove() {
        // this.wrapper.remove();
    }

    createPointerTracker() {
        const pointer = document.createElement('div');
        console.log(pointer);
        pointer.className = 'pointer-tracker';
        this.pointer = pointer;
        this.wrapper.appendChild(this.pointer);

        this.wrapper.addEventListener('mousemove', (e) => {
            this.pointer.classList.add('open');
            this.pointer.style.left = e.clientX + 'px';
            this.pointer.style.top = e.clientY + 'px';
        });

        // set initialPosition 



    }

    closePointerTracker() {
        this.pointer.classList.remove('open');
    }

}