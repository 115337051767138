import { runInifiniteScroll } from '../partials/infinite-scroll';


export class Page {
    constructor() {
    }

    index() {
        return () => { };
    }

    init() {
    }
}