
import { runComponents } from './components';
import { runLenis } from './modules/lenis';
import { initSwiper } from './modules/swiper';
import { runSwup } from './modules/swup';
import { pages } from './pages';
import { runBurgerMenu } from './partials/nav';
import { Router } from './router';
import { runColorMode } from './utils/colors';

export class App {
    constructor() {
    }

    run() {
        document.addEventListener('DOMContentLoaded', () => {
            runLenis();
            runSwup();
            initSwiper();
            runColorMode();
            runBurgerMenu();
            new Router(pages, window.swup);
            runComponents();
        });
    }


}

