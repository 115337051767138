
export function runBurgerMenu() {
    const burgerMenu = document.querySelector('.burger-menu');
    const menu = document.querySelector('.menu');

    if (burgerMenu) {
        burgerMenu.addEventListener('click', () => {
            burgerMenu.classList.toggle('active');
            menu.classList.toggle('active');
        });
    }
}