
export function runColorMode() {
    const accent = getComputedStyle(document.documentElement).getPropertyValue('--color-accent');
    const colorPicker = document.querySelector('.color-picker');
    const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--color-bg');
    const colorPickerBg = document.querySelector('.color-picker-bg');
    const textColor = getComputedStyle(document.documentElement).getPropertyValue('--color-text');
    const colorPickerText = document.querySelector('.color-picker-text');

    if (colorPicker) {
        colorPicker.value = accent;
        colorPicker.addEventListener('input', (e) => {
            document.documentElement.style.setProperty('--color-accent', e.target.value);
        });
    }

    if (colorPickerBg) {
        colorPickerBg.value = bgColor;
        colorPickerBg.addEventListener('input', (e) => {
            document.documentElement.style.setProperty('--color-bg', e.target.value);
        });
    }

    if (colorPickerText) {
        colorPickerText.value = textColor;
        colorPickerText.addEventListener('input', (e) => {
            document.documentElement.style.setProperty('--color-text', e.target.value);
        });
    }

    function switchMode() {
        let colorMode = localStorage.getItem('color-mode');
        if (colorMode === 'dark') {
            colorMode = 'light';
        } else {
            colorMode = 'dark';
        }
        localStorage.setItem('color-mode', colorMode);
        document.documentElement.setAttribute('data-color-scheme', colorMode);
    }

    const colorModeToggle = document.querySelector('.color-mode-toggle');

    if (colorModeToggle) {
        colorModeToggle.addEventListener('click', switchMode);
    }

    let colorMode = localStorage.getItem('color-mode');
    if (!colorMode) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            colorMode = 'dark';
        } else {
            colorMode = 'light';
        }
        localStorage.setItem('color-mode', colorMode);
    }
    document.documentElement.setAttribute('data-color-scheme', colorMode);
}