export function controlsVideo() {
    const playButton = document.querySelector('.play-button');
    const mutedButton = document.querySelector('.muted-button');

    playButton?.addEventListener('click', () => {
        const video = document.querySelector(`${playButton.dataset.video}`);
        if (video.paused) {
            video.play();
            playButton.classList.add('is-playing');
        } else {
            video.pause();
            playButton.classList.remove('is-playing');
        }
    }
    );

    mutedButton?.addEventListener('click', () => {
        const video = document.querySelector(`${mutedButton.dataset.video}`);
        if (video.muted) {
            video.muted = false;
            mutedButton.classList.remove('is-muted');
        } else {
            video.muted = true;
            mutedButton.classList.add('is-muted');
        }
    }
    );
}