import { About } from './about';
import { Contact } from './contact';
import { Home } from './home';
import { Member } from './member';
import { Project } from './project';
import { Projects } from './projects';
import { Team } from './team';

export const pages = {
    Home,
    About,
    Project,
    Projects,
    Team,
    Member,
    Contact,
};